import React, { useState, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Spinner } from "react-bootstrap";
import styled from "styled-components";
import instance from "../utilities/axios.js";
import GoogleAutoCompleteAddress from "../components/GoogleAutoCompleteAddress";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { updateShopObject } from "../redux/actions/shopActions";
import { resetDefaultPlansState } from "../redux/actions/defaultPlansActions";
import { UnderlineTitleBlue } from "../components/StyledComponentsAll";
import { graphql } from "gatsby";
import moment from "moment";
import { DatePicker } from "antd";
import { constants } from "../redux/constants";
import { Trans, useTranslation } from "react-i18next";
import PaymentProcedure from "../components/MobilePaymentStepper";
import { formatPhoneData, getPartnerIdFromPageLocation, goTo, numbersOnly, phoneNumbersOnly, encryptPromocode } from "../utilities/utils";
import { api } from "../utilities/api";
import { planIncludesDescription } from "../utilities/constants";
import ProratedPlanModal from "../components/ProratedPlanModal";
import RadioList from "../components/RadioList";
import SimCardDetail from "../components/SimCardDetail";
import SwitchButton from "../components/SwitchButton";
import { proxyApi } from "../utilities/proxy-api";
import { ApiRequest } from "../models/proxy-api-request";
import { states, provinces, carriers } from "../utilities/constants";
import AppYesNo from "../components/AppYesNo";
import CountriesDropDownList from "../components/CountriesDropDownList";
import { toFriendlyErrorMessage, validateEmail } from "../utilities/portaone-utils";
import { ConfigProvider } from 'antd';
import { useI18next } from "gatsby-plugin-react-i18next";
import frFR from 'antd/lib/locale/fr_FR';
import esES from 'antd/lib/locale/es_ES';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import zhCN from 'antd/lib/locale/zh_CN';
import viVN from 'antd/lib/locale/vi_VN';
import koKR from 'antd/lib/locale/ko_KR';
import ptPT from 'antd/lib/locale/pt_PT';
import StatesDropDownList from "../components/StatesDropDownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import CanadaPostAutoCompleteAddress from "../components/CanadaPostAutoCompleteAddress";

export default function infoFillup(state) {
  let shopDetails = useSelector((state: RootState) => state.shop);
  let routeDetails = useSelector((state: RootState) => state.route);
  let countryCodes = useSelector((state: RootState) => state.countryCodes);
  const { t } = useTranslation();

  const encodedUSPromocode = shopDetails.usPromocode ? encryptPromocode(shopDetails.usPromocode) : '';

  const { language } = useI18next();

  let locale = null;

  if (language === 'fr') {
    locale = frFR;
  } else if (language === 'sp') {
    locale = esES;
  } else if (language === 'en') {
    locale = enUS;
  } else if (language === 'jp') {
    locale = jaJP;
  } else if (language === 'zh') {
    locale = zhCN;
  } else if (language === 'vi') {
    locale = viVN;
  } else if (language === 'ko') {
    locale = koKR;
  } else if (language === 'pt') {
    locale = ptPT;
  }

  const isEastAsianLanguage = (language === 'jp' || language === 'zh' || language === 'ko');

  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const NO_HIDING = -1;
  const DEFAULT_COUNTRY = shopDetails.country === constants.CANADA ? constants.CANADA : constants.USA;
  const DEFAULT_EMPTY_CARRIER = t("Select a Carrier");
  const DEFAULT_EMPTY_PROVINCE = t(shopDetails.country === constants.CANADA ? "Select a Province" : "Select a State");
  const ONE_DAY_DELIVERY = 3;
  const [countryPlan, setCountryPlan] = useState(shopDetails.country);
  const isCanada = shopDetails.country === constants.CANADA;
  const [emailExistError, setEmailExistError] = useState(false);
  const [emailMatchError, setEmailMatchError] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [checkoutDetails, setCheckoutDetails] = useState({ portInStatus: false, activationDate: "", activationDateObject: null, usPortInWireless: false, usPortInVerified: false });
  const [errorMsgs, setErrorMsgs] = useState({ emailError: "" });
  const [isAutoCompleteFinished, setIsAutoCompleteFinished] = useState(false);
  const [disableEditBar, setDisableEditBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [step1, setStep1] = useState("In Progress");
  const [step2, setStep2] = useState("pending");
  const [showProratedPlanModal, setShowProratedPlanModal] = useState(shopDetails.isPostpaid);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [mobile, setMobile] = useState(false);
  const [isFreedomPortin, setIsFreedomPortin] = useState(false);
  const [usaPhoneNumber, setUSAPhoneNumber] = useState('');
  const [usaAccountNumber, setUSAAccountNumber] = useState('');
  const [usaPINNumber, setUSAPINNumber] = useState('');
  const [usaPortinVerifyError, setUSAPortinVerifyError] = useState('');
  const [usaPortInVerifying, setUSAPortInVerifying] = useState(false);
  const [notAPOBox, setNotAPOBox] = useState(false);
  const [isServiceAddressAutoCompleteFinished, setIsServiceAddressAutoCompleteFinished] = useState(false);
  const [usCheckoutError, setUSCheckoutError] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [shippingCountryCodeIso, setShippingCountryCodeIso] = useState('');
  const [disableChangeBtn, setDisableChangeBtn] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [usPortinState, setUSPortinState] = useState({ name: 'Delaware', code: 'DE' });
  const [invalidCarrier, setInvalidCarrier] = useState(false);
  const [canadianPortinNumber, setCanadianPortinNumber] = useState("");
  const [invalidCanadianPortin, setInvalidCanadianPortin] = useState(false);
  const [cemChecked, setCemChecked] = useState(false);
  const [cemError, setCemError] = useState(false);

  const isTelusPlan = shopDetails.planCarrier.toLowerCase() === 'telus';
  const isRogersPlan = shopDetails.planCarrier.toLowerCase() === 'rogers';
  const termsLink = "https://gophonebox.com/terms-of-service/";

  // carriers that cannot have portin for Telus plan
  const invalidTelusCarriers = [
    'Koodo',
    'Telus',
    'Public Mobile',
    'CTExcel'
  ]

  // Quebec area codes to disallow for port-in
  const quebecAreaCodes = [
    "263",
    "354",
    "367",
    "418",
    "438",
    "450",
    "468",
    "514",
    "579",
    "581",
    "819",
    "873"
  ]

  useEffect(() => {
    // need to load countries into the store so country drop down list can use them
    api.getCountryList()
      .then((res) => {
        dispatchShop({ countries: res.data })
      });

    if (!shopDetails.planId || shopDetails.planId === 0) {
      goTo("https://gophonebox.com/plans");
    } else {
      dispatch(resetDefaultPlansState());
      if (routeDetails.routeString === "shop" && shopDetails.country === constants.USA) {
        let simcard_order = { simcardOrder: true };
        dispatchShop(simcard_order);
      }
      handlePortIn(false);
      resetStoreFormDefaultState();
    }

    if (shopDetails.country === constants.USA) {
      setDisableChangeBtn(true);
    } else {
      setDisableChangeBtn(false);
    }


    // begin checkout
    // ask trena digital about this if it's incorrect.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'begin_checkout',
      value: shopDetails.planId,
      ecommerce: {
        items: [
          {
            item_name: shopDetails.data,
            item_id: shopDetails.planId,
            price: shopDetails.plan_fee,
            item_category: !isCanada ? "USA Prepaid" : shopDetails.isPostpaid ? "Canada Postpaid" : "Canada Prepaid",
            item_variant: shopDetails.planTypeD,
            index: 0
          }
        ]
      }
    });

  }, []);

  useEffect(() =>  { 
    updateOptionList(shopDetails.shipping_countryIndex, DEFAULT_COUNTRY) 
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const insert = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
  }

  const getTaxRate = () => {
    if (shopDetails.country === constants.CANADA) {
      return shopDetails.gst_rate + shopDetails.pst_rate;
    }
    return shopDetails.us_tax_rate;
  };

  const getTaxAmount = () => {
    if (shopDetails.country === constants.CANADA) {
      return (shopDetails.gst_amt + shopDetails.pst_amt).toFixed(2);
    }
    return shopDetails.us_tax_amount ? shopDetails.us_tax_amount.toFixed(2) : 0.00;
  }


  const handleContactNumber = (e) => {
    const value = phoneNumbersOnly(e.target.value);
    setContactNumber(value);
    dispatchShop({ shipping_contact_number: value });
  };

  const handleUSAPhoneNumber = (e) => {
    const value = phoneNumbersOnly(e.target.value);
    setUSAPhoneNumber(value);
    dispatchShop({ us_portin_phone_number: value });
  };

  const handleUSAAccountNumber = (e) => {
    const value = e.target.value;
    setUSAAccountNumber(e.target.value);
    dispatchShop({ us_portin_account_number: value });
  };

  const handleUSAPINNumber = (e) => {
    const value = numbersOnly(e.target.value);
    setUSAPINNumber(value);
    dispatchShop({ us_portin_pin_number: value });
  };

  const handleNotAPOBox = (event) => {
    const value = event?.target?.checked ?? false;
    setNotAPOBox(value);
    dispatchShop({ us_portin_is_not_po_box: value });
  }

  const handlePortInIsWireless = (value) => {
    if (checkoutDetails.usPortInWireless === value) {
      return;
    }
    setCheckoutDetails({ ...checkoutDetails, usPortInWireless: value, usPortInVerified: false })
  };

  const handleCityChange = (e) => {
    switch (e.target.value.toLowerCase()) {
      case "thunder bay":
        alert("The area code of this city is not available at this time. We will, however, provide you with a 416 or 617 Area Code as a substitute of this. Please note that this won't affect your service as our plans include unlimited Canada wide calling and texting which allows you to call and text anyone inside Canada with our phone numbers.");
        break;
    }
    dispatchShop({ service_city: e.target.value });
  };

  const resetActivationDate = () => {
    setCheckoutDetails({ ...checkoutDetails, activationDateObject: null });
    dispatchShop({ us_activation_date: null });
    dispatchShop({ startDate: null });
    dispatchShop({ endDate: null });
  };

  const compareEmailAddress = (email, confirmEmail) => {
    if (email.toLowerCase() !== confirmEmail.toLowerCase()) {
      setErrorMsgs({ ...errorMsgs, emailError: t("Your email address is not the same!") });
      setEmailMatchError(true);
    } else {
      setErrorMsgs({ ...errorMsgs, emailError: "" });
      setEmailMatchError(false);
      if (confirmEmail) {
        validateEmailAddress(confirmEmail);
      }
    }
  };

  const getServiceCountryId = () => {
    switch (shopDetails.country) {
      case constants.USA:
        return 247;
      default:
        return 42;
    }
  };

  const handleStepChange = (num: number, enable?: string) => {
    setShowProratedPlanModal(shopDetails.isPostpaid);
    if (enable === "enable") {
      setDisableEditBar(false);
    }
    setStep(num);
    window.scrollTo(0, 0);
  };

  const checkout = () => {
    if (!cemChecked && shopDetails.country !== constants.USA) {
      setCemError(true);
      return;
    }
    setCemError(false);

    // do dataLayer push

    // purchase intent
    // when they actually click "proceed to checkout"
    // ask trena digital about this if it's incorrect.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'purchase_intent',
      value: shopDetails.planId,
      ecommerce: {
        items: [
          {
            item_name: shopDetails.data,
            item_id: shopDetails.planId,
            price: shopDetails.plan_fee,
            item_category: !isCanada ? "USA Prepaid" : shopDetails.isPostpaid ? "Canada Postpaid" : "Canada Prepaid",
            item_variant: shopDetails.planTypeD,
            index: 0
          }
        ]
      }
    });

    if (shopDetails.country === constants.USA) {
      setLoadingNext(true);
      setUSCheckoutError('');

      const request = new ApiRequest({
        // firstname, lastname, and email are set in previous step
        // we utilize the firstname, lastname, and email from customer record to set it for account
        // record on the backend
        i_customer: shopDetails.us_i_customer,
        zip: shopDetails.shipping_zipPostal,
        is_esim: shopDetails.esim,
        is_sim_card_order: shopDetails.delivery_needed,
        shipping_code_type: shopDetails.us_account_shipping_code_type,
        shipping_code_country: shopDetails.us_account_shipping_code_country,
        shipping_code_country_iso: shippingCountryCodeIso === '' ? 'US' : shippingCountryCodeIso,
        i_product: shopDetails.us_main_i_product,
        state: shopDetails.shipping_stateProvince,
        address_line_1: shopDetails.shipping_streetAddress,
        address_line_2: shopDetails.shipping_unitNo,
        city: shopDetails.shipping_city,
        port_in: checkoutDetails.portInStatus,
        contact_number: shopDetails.shipping_contact_number,
        activation_date: checkoutDetails.activationDateObject,
        port_in_details: {
          phone_number: shopDetails.us_portin_phone_number,
          account_number: shopDetails.us_portin_account_number,
          account_password: shopDetails.us_portin_pin_number,
          address_line_1: shopDetails.us_portin_service_street_address,
          address_line_2: shopDetails.us_portin_service_unitNo,
          city: shopDetails.us_portin_service_city,
          state: shopDetails.us_portin_service_state_province,
          zip: shopDetails.us_portin_service_zip_postal,
        }
      });
      proxyApi.checkout.checkout(request).then(res => {
        if (!res.success && res.error) {
          setUSCheckoutError(toFriendlyErrorMessage(res.error));
          setLoadingNext(false);
          console.error(res.error);
          return;
        }
        dispatchShop({ us_i_account: res.data.i_account })
        goTo('/checkout');
      }).catch(err => {
        console.error(err);
        setLoadingNext(false);
      });
      return;
    }

    // canada
    generateOaId();
  }

  const generateOaId = () => {
    setLoadingNext(true);
    const url = shopDetails.isPostpaid
      ? "/Activation/PostActivationInfoPostpaid"
      : "/Activation/PostActivationInfoPrepaid";

    const promoCodeForGenerateOaId = shopDetails.isPostpaid
      ? shopDetails.promocode 
      : shopDetails.prepaidPromocode;

    const partnerIds = getPartnerIdFromPageLocation(location);
    instance
      .post(url, { ...shopDetails, bizId: partnerIds.canadaPartnerId === 0 ? null : partnerIds.canadaPartnerId, consent_cem: cemChecked, promocode: promoCodeForGenerateOaId })
      .then((response) => {
        let data = response.data;
        if (data === 0) {
          alert("Sorry something went wrong.");
        } else {
          dispatchShop({ oa_paId: data });
          goTo("/checkout", { state: { data } });
        }
        setLoadingNext(false);
      })
      .catch((error) => {
        setLoadingNext(false);
        console.error(error);
      });
  };

  const handlePortIn = (value) => {

    if(shopDetails.country === constants.CANADA && value){
      alert("You will receive a SMS to authorize porting your phone number from your old carrier. Please reply within 90 minutes from when you received it to complete the process. Please keep your old sim card active until you lose service with your old provider.")
    }

    if (checkoutDetails.portInStatus === value) {
      return;
    }

    let portin_carrier = value
      ? { portin_carrier: shopDetails.portin_carrier_local }
      : { portin_carrier: "" };

    let portin_accountNo = value
      ? { portin_accountNo: shopDetails.portin_accountNo_local }
      : { portin_accountNo: "" };

    let portin_phoneNo = value
      ? { portin_phoneNo: shopDetails.portin_phoneNo_local }
      : { portin_phoneNo: "" };

    let portin_other = value
      ? { portin_other: shopDetails.portin_other_local }
      : { portin_other: "" };

    setCheckoutDetails({ ...checkoutDetails, portInStatus: value, usPortInWireless: false, usPortInVerified: false });
    dispatchShop(portin_carrier);
    dispatchShop(portin_accountNo);
    dispatchShop(portin_phoneNo);
    dispatchShop(portin_other);
  };

  const handlePortInVerify = () => {
    setUSAPortinVerifyError('');
    setUSAPortInVerifying(true);
    proxyApi.plintron.extMnpCheckPortinEligibility(new ApiRequest({ pmsisdn: `1${usaPhoneNumber}` }))
      .then(res => {
        const verified = res.success;
        const errorMessage = 'Sorry, the phone number you entered is not eligible for port in, please check with your current service provider to find out.';
        setCheckoutDetails({ ...checkoutDetails, usPortInVerified: verified });
        if (!verified) {
          setUSAPortinVerifyError(errorMessage);
        }
        setUSAPortInVerifying(false);
      }).catch(err => {
        console.error(err);
        setUSAPortinVerifyError(errorMessage);
        setUSAPortInVerifying(false);
      });
  };

  const needInteractionID = () => shopDetails.portin_carrier === "Rogers" && (shopDetails.planCarrier === "Rogers" || shopDetails.planCarrier === "R");

  const verifyCheckoutDetails = (): boolean => {
    if (!validateEmail(shopDetails.email)) {
      setErrorMessage(t("Your email address must contain only latin letters, digits, '-', '_', '@', and '.'."));
      return false;
    };

    if (emailExistError) {
      setErrorMessage(t("Your email address is already in use!"));
      return false;
    }

    if (emailMatchError) {
      setErrorMessage(t("Your email address is not the same!"));
      return false;
    }

    if (isFreedomPortin && shopDetails.portin_accountNo.length !== 15) {
      setErrorMessage(t("Error: IMEI should only be 15 digits. Please kindly double check again."));
      return false;
    }

    const emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const everythingButChineseCharacters = /^[\u0000-\u4DFF\u9FA6-\uFFFF]*$/;
    const validationMap = new Map<string, any>();
    // validate personal info
    validationMap.set("First Name", shopDetails.firstName);
    validationMap.set("Last Name", shopDetails.lastName);
    validationMap.set("Email", shopDetails.email);
    validationMap.set("Activation Date", checkoutDetails.activationDateObject);
    validationMap.set("Confirm Email", shopDetails.emailConfirm);

    // validate service provice/city
    if (shopDetails.country === constants.CANADA) {
      validationMap.set("Service Province", shopDetails.service_province === DEFAULT_EMPTY_PROVINCE ? "" : shopDetails.service_province);
      validationMap.set("Service City", shopDetails.service_city);
    }

    // validate shipping location
    if (shopDetails.delivery_needed) {
      validationMap.set("Delivery Street Address", shopDetails.shipping_streetAddress);
      validationMap.set("Delivery City", shopDetails.shipping_city);
      validationMap.set("Delivery Country", shopDetails.shipping_country);
      validationMap.set(shopDetails.country === constants.CANADA ? "Delivery Province" : "Delivery State", shopDetails.shipping_stateProvince);
      validationMap.set(shopDetails.country === constants.CANADA ? "Delivery Postal Code" : "Delivery Zip Code", shopDetails.shipping_zipPostal);
    }

    // validate port in options
    if (checkoutDetails.portInStatus) {
      if (shopDetails.country === constants.CANADA) {
        validationMap.set("Carrier", shopDetails.portin_carrier === DEFAULT_EMPTY_CARRIER ? "" : shopDetails.portin_carrier);
        validationMap.set("Account Number", shopDetails.portin_accountNo);
        validationMap.set("Phone Number", shopDetails.portin_phoneNo);
        if (needInteractionID()) {
          validationMap.set("InteractionID", shopDetails.portin_other);
        }
      }
      if (shopDetails.country === constants.USA) {
        if (!checkoutDetails.usPortInWireless) {
          validationMap.set("Wireless Port In Phone Number", checkoutDetails.usPortInWireless);
        }
        if (!checkoutDetails.usPortInVerified) {
          validationMap.set("Verified Port In Phone Number", checkoutDetails.usPortInVerified);
        }
        validationMap.set("Account Number", shopDetails.us_portin_account_number);
        validationMap.set("PIN Number", shopDetails.us_portin_pin_number);
        validationMap.set("Port In Service Street Address", shopDetails.us_portin_service_street_address);
        validationMap.set("Port In Service City", shopDetails.us_portin_service_city);
        validationMap.set("Port In Service Country", shopDetails.us_portin_service_country);
        validationMap.set(shopDetails.country === constants.CANADA ? "Port In Service Province" : "Port In Service State", shopDetails.us_portin_service_state_province);
        validationMap.set(shopDetails.country === constants.CANADA ? "Port In Service Postal Code" : "Port In Service Zip Code", shopDetails.us_portin_service_zip_postal);
        validationMap.set("Port In Address Must Not Be a P.O. Box", shopDetails.us_portin_is_not_po_box);
      }
    }

    if (shopDetails.sfName === 'Express') {
      validationMap.set("Contact Number", shopDetails.shipping_contact_number);
    }

    // start validation, check if port-in number is invalid
    if (invalidCarrier) {
      setErrorMessage(t('The port in carrier you have selected is invalid.'));
      return false;
    }

    if (invalidCanadianPortin) {
      setErrorMessage(t('Sorry, the phone number you\'ve entered is not eligible to Port In.'));
      return false;
    }
    
    // do rest of validations
    for (const entry of Array.from(validationMap.entries())) {
      const key = entry[0];
      const value = entry[1];
      if (!value || value.toString().trim() === "") {
        setErrorMessage(t(`${key} is required.`));
        return false;
      }

      switch (key) {
        case "Email":
        case "Confirm Email":
          if (!emailReg.test(value)) {
            setErrorMessage(t("Please fill in correct email address."));
            return false;
          }
          break;

        case "Activation Date":
          break;

        default:
          if (!everythingButChineseCharacters.test(value)) {
            setErrorMessage("Please fill " + key + " with number or alphabet.");
            return false;
          }
      }
    }
    return true;
  };

  const getShippingAddress = () => {
    const { shipping_unitNo, shipping_streetAddress, shipping_city } = shopDetails;
    return `${shipping_unitNo} ${shipping_streetAddress}, ${shipping_city}`.trim();
  };

  // update shipping value: update all of these values to redux.
  const updateGlobalShippingInfo = async () => {
    const { shipping_stateProvince, shipping_zipPostal, shipping_countryIndex, delivery_province, sfID, sfName, sfFee } = shopDetails;

    let sfIDForSubmit = sfID;
    let sfNameForSubmit = sfName;
    let sfFeeForSubmit = sfFee;
    if ((shopDetails.simcard_no && shopDetails.simcard_no.trim().length > 0) || shopDetails.esim) {
      sfIDForSubmit = 0;
      sfNameForSubmit = "";
      sfFeeForSubmit = 0;
    }
    if (sfIDForSubmit === 0) {
      sfNameForSubmit = "";
    }

    new Promise<void>(resolve => {
      dispatchShop({ delivery_address: getShippingAddress() });
      dispatchShop({ delivery_countryId: shipping_countryIndex });
      dispatchShop({ delivery_postal: shipping_zipPostal });
      // "shipping_stateProvince" is used elsewhere as an abbreviated version
      // we want to use the unabbreviated "delivery_province" otherwise.
      dispatchShop({ delivery_province: isCanada ? delivery_province : shipping_stateProvince });
      dispatchShop({ sfID: sfIDForSubmit });
      dispatchShop({ sfName: sfNameForSubmit });
      dispatchShop({ sfFee: sfFeeForSubmit });
      resolve();
    })
  };

  const handleNext = async () => {
    if (verifyCheckoutDetails()) {
      setLoadingNext(true);
      await updateGlobalShippingInfo();
      setDisableEditBar(true);
      dispatchShop({ editMode: false });

      // do dataLayer push

      // add shipping info
      // indicats that shipping info is complete and added correctly.
      // ask trena digital about this if it's incorrect.
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: 'add_shipping_info',
        value: shopDetails.planId,
        ecommerce: {
          shipping_tier: shopDetails.sfName,
          items: [
            {
              item_name: shopDetails.data,
              item_id: shopDetails.planId,
              price: shopDetails.plan_fee,
              item_category: !isCanada ? "USA Prepaid" : shopDetails.isPostpaid ? "Canada Postpaid" : "Canada Prepaid",
              item_variant: shopDetails.planTypeD,
              index: 0
            }
          ]
        }
      });

      if (shopDetails.isPostpaid) {
        getTotalPostpaid();
      } else {
        getTotalPrepaid();
      }
    }
  };

  const resetStoreFormDefaultState = () => {
    dispatchShop({ firstName: '' });
    dispatchShop({ lastName: '' });
    dispatchShop({ email: '' });
    dispatchShop({ emailConfirm: '' });
    dispatchShop({ portin_accountNo: '' });
    dispatchShop({ portin_accountNo_local: '' });
    dispatchShop({ portin_phoneNo: '' });
    dispatchShop({ portin_phoneNo_local: '' });
    dispatchShop({ portin_other: '' });
    dispatchShop({ portin_other_local: '' });
    dispatchShop({ startDateLocal: null });
    dispatchShop({ endDateLocal: null });
    dispatchShop({ startDate: null });
    dispatchShop({ endDate: null });
    dispatchShop({ shippingStreetAddress: '' });
    dispatchShop({ shippingCity: '' });
    dispatchShop({ shippingProvince: '' });
    dispatchShop({ shipping_unitNo: '' });
    dispatchShop({ shipping_streetAddress: '' });
    dispatchShop({ shipping_city: '' });
    dispatchShop({ shipping_stateProvince: '' });
    dispatchShop({ shipping_zipPostal: '' });
    dispatchShop({ shipping_contact_number: '' });
    dispatchShop({ service_province_fullname: t(shopDetails.country === constants.CANADA ? "Select a Province" : "Select a State") });
    dispatchShop({ service_province: '' });
    dispatchShop({ portin_carrier_local: t('Select a Carrier') });
    dispatchShop({ us_portin_service_unitNo: '' });
    dispatchShop({ us_portin_service_street_address: '' });
    dispatchShop({ us_portin_service_city: '' });
    dispatchShop({ us_portin_service_state_province: '' });
    dispatchShop({ us_portin_service_country: 'United States' });
    dispatchShop({ us_portin_service_country_index: 247 });
    dispatchShop({ us_portin_service_zip_postal: '' });
    dispatchShop({ us_portin_service_full_address: '' });
    dispatchShop({ us_portin_account_number: '' });
    dispatchShop({ us_portin_pin_number: '' });
    dispatchShop({ us_portin_phone_number: '' });
  };

  const handleActivationDateChange = (val) => {
    let newDate = val;

    // checking EST dates only for USA
    // if (shopDetails.country === constants.USA) {
    //   console.log('val', val);
    //   let currentHours = moment(val).hours();
    //   console.log(currentHours);

    //   // custom date offset in hours
    //   let cdOffset = moment(val).utcOffset()/60;
    //   let utcHours = currentHours - cdOffset;

    //   // find the offset in EST timezone (-4 UTC)
    //   let estHours = utcHours - 4

    //   console.log('estHours IN HADC: ', estHours);

    //   // if it's after midnight in EST, activation date should become the previous day.
    //   // this is due to date shifting for date selection.
    //   if (estHours >= 24) {
    //     newDate = moment(val).subtract(1, "days").format("YYYY-MM-DD");
    //   }

    //   console.log("final calculated NEWDATE: ", newDate);
    // }

    setCheckoutDetails({ ...checkoutDetails, activationDateObject: newDate });
    dispatchShop({ us_activation_date: newDate });
    let startDateLocalInMoment = moment(newDate);
    let endDateLocalInMoment = moment(newDate).add(+shopDetails.planTypeD - 1, "days");

    dispatchShop({ startDateLocal: startDateLocalInMoment });
    dispatchShop({ endDateLocal: shopDetails.isPostpaid ? null : endDateLocalInMoment });
    dispatchShop({ startDate: moment(startDateLocalInMoment).format("YYYY-MM-DD 00:00:00") });
    dispatchShop({ endDate: shopDetails.isPostpaid ? '' : moment(endDateLocalInMoment).format("YYYY-MM-DD 00:00:00") });
  };

  const getServiceAddressAutoComplete = (full_address) => {
    const longCountryName = full_address[5];
    const fullAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}, ${longCountryName}, ${full_address[4]}`;
    dispatchShop({ us_portin_service_street_address: full_address[0] });
    dispatchShop({ us_portin_service_city: full_address[1] });
    dispatchShop({ us_portin_service_country: longCountryName });
    dispatchShop({ us_portin_service_state_province: full_address[2] });
    dispatchShop({ us_portin_service_zip_postal: full_address[4] });
    dispatchShop({ us_portin_service_full_address: fullAddress });
    setUSPortinState({ name: full_address[6], code: full_address[2] });
    setIsServiceAddressAutoCompleteFinished(true);
  };

  const getShippingAddressAutoComplete = (full_address) => {
    const longCountryName = full_address[5];
    const fullAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}, ${longCountryName}, ${full_address[4]}`;
    const deliveryAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}`
    const countryCode = getCountryCode(longCountryName);
    dispatchShop({ shippingStreetAddress: full_address[0] });
    dispatchShop({ shippingCity: full_address[1] });
    dispatchShop({ shippingProvince: full_address[2] });
    dispatchShop({ delivery_province: full_address[2] });
    dispatchShop({ shipping_streetAddress: full_address[0] });
    dispatchShop({ shipping_city: full_address[1] });
    dispatchShop({ shipping_country: longCountryName });
    dispatchShop({ shipping_stateProvince: full_address[2] });
    dispatchShop({ shipping_zipPostal: full_address[4] });
    dispatchShop({ shipping_fullAddress: fullAddress });
    dispatchShop({ delivery_address: deliveryAddress });
    dispatchShop({ delivery_postal: full_address[4] });
    dispatchShop({ delivery_countryId: parseInt(countryCode) });
    setShippingCountryCodeIso(shopDetails.countries.find(s => s.CountryCode == countryCode)?.CountryCodeISO);
    setIsAutoCompleteFinished(true);
    setContactNumber('');
    updateOptionList(countryCode, longCountryName);
  };

  const getCountryCode = (country_name) => countryCodes.countryCodeList.find((obj) => obj.CountryName === country_name).CountryCode;

  const handleProvinceMenuClick = (e) => {
    const key = +e.key;
    let province = provinces.find(s => s.key === key);
    if (province) {
      switch (province.code) {
        case 'NT':
        case 'NU':
        case 'YT':
          alert("The Area Code of this province is not available at this time. We will, however, provide you with 416 or 647 Area code as a substitute of this. Please note that this won't affect your service as our plans include unlimited Canada wide calling and texting which allows you to call and text anyone inside Canada with our phone numbers.");
          break;
        case 'QC':
          // QC plans are fine for Telus.
          if (isTelusPlan) {
            break;
          }
          alert("514 and 438 Area Codes are not available at this time. We will, however, provide you with 416, 437 or 647 Area code as a substitute of this. Please note that this won't affect your service as our plans include unlimited Canada wide calling and texting which allows you to call and text anyone inside Canada with our phone numbers.");
          break;
      }
    }
    dispatchShop({ service_province: !province ? "" : province.code });
    dispatchShop({ service_province_fullname: !province ? t("Select a Province") : province.name });
  }

  const handleCarrierMenuClick = (e) => {
    const key = +e.key;
    let carrier = carriers.find(s => s.key === key);
    let name = !carrier ? DEFAULT_EMPTY_CARRIER : carrier.name;

    dispatchShop({ portin_carrier: name });
    dispatchShop({ portin_carrier_local: name });

    if (name.toLowerCase() === "freedom") {
      setIsFreedomPortin(true);
    } else {
      setIsFreedomPortin(false);
    }

    if (invalidTelusCarriers.includes(name) && isTelusPlan) {
      setInvalidCarrier(true);
      return;
    } else if (name.toLowerCase() === "rogers" && isRogersPlan) {
      // cannot do Rogers to Rogers portin
      setInvalidCarrier(true);
      return;
    } else {
      setInvalidCarrier(false);
    }
  }

  const handleStateMenuClick = (e) => {
    const key = +e.key;
    let state = states.find(s => s.key === key);
    dispatchShop({ service_province: !state ? "" : state.code });
    dispatchShop({ service_province_fullname: !state ? "Select a State" : state.name });
  }

  const getTotalPostpaid = () => {
    const payload = {
      orderSimCard: shopDetails["simcard_no"] ? false : true,
      planID: shopDetails["planId"],
      countryId: getServiceCountryId(),
      provinceCode: shopDetails["service_province"],
      start: shopDetails["startDate"],
      promocode: shopDetails["promocode"],
      sfID: shopDetails["sfID"],
      esim: shopDetails["esim"]
    };
    instance
      .get("/Activation/GetTotalPostpaid", {
        // if we have a simcard_no
        // that means the simcard_no got verified, which means no simcard fee.
        // otherwise, we will need to ship simcard and needs sim card fee.
        params: payload,
      })
      .then((response) => {
        const data = response.data[0];
        dispatchShop({ plan_fee: data.plan_Amt });
        dispatchShop({ prorateDataCap: data.prorate_DataCap });
        dispatchShop({ prorateStartDate: data.prorate_Start });
        dispatchShop({ prorateEndDate: data.prorate_End });
        dispatchShop({ proratePct: data.prorate_Pct });
        dispatchShop({ prorate_duration: data.prorate_Duration });
        dispatchShop({ simcard_fee: data.simcard_amt });
        dispatchShop({ sfFee: data.shipping_Fee });
        dispatchShop({ prorate_fee: data.prorate_Amt.toFixed(2) });
        dispatchShop({ charge_duration: data.charge_Duration });
        dispatchShop({ gst_rate: data.gst_rate });
        dispatchShop({ pst_rate: data.pst_rate });
        dispatchShop({ gst_amt: data.gst_Amt });
        dispatchShop({ pst_amt: data.pst_Amt });
        dispatchShop({ subtotal: data.subtotal });
        dispatchShop({ promocredit: data.promo_Amt });
        dispatchShop({ total: data.total });
        dispatchShop({ startDate: moment(shopDetails.startDate).format("YYYY-MM-DD 00:00:00") });
        dispatchShop({ service_countryId: getServiceCountryId() });
        dispatchShop({ chargeStart: data.charge_Start });
        setStep1("completed");
        setStep2("In Progress");
        handleStepChange(2);
        setLoadingNext(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingNext(false);
      });
  };

  const getTotalPrepaid = () => {
    if (shopDetails.country === constants.USA) {
      let startDateUS = insert(shopDetails.startDateLocal._d.toString().substring(4,15), 6, ',');
      let endDateUS = insert(shopDetails.endDateLocal._d.toString().substring(4,15), 6, ',');

      const partnerIds = getPartnerIdFromPageLocation(location);
      proxyApi.checkout.prepaidTotal(new ApiRequest({
        activation_date: checkoutDetails.activationDateObject,
        email: shopDetails.email,
        first_name: shopDetails.firstName,
        i_rep: partnerIds.usPartnerId !== 0 ? partnerIds.usPartnerId : shopDetails.us_i_rep,
        last_name: shopDetails.lastName,
        plan_fee: shopDetails.plan_fee,
        promo_credit: shopDetails.promocredit,
        shipping_fee: shopDetails.sfFee,
        sim_card_number: shopDetails.simcard_no,
        shipping_i_subscription: shopDetails.us_shipping_i_subscription,
        shipping_code_type: shopDetails.us_account_shipping_code_type,
        shipping_code_country: shopDetails.us_account_shipping_code_country,
        zip: shopDetails.shipping_zipPostal,
        plan_subscription_id: shopDetails.us_plan_i_subscription,
        promo_code: encodedUSPromocode,
        is_esim: shopDetails.esim
      }))
        .then(res => {
          if (!res.success && res.error) {
            setUSCheckoutError(toFriendlyErrorMessage(res.error));
            setLoadingNext(false);
            console.error(res.error);
            return;
          }
          dispatchShop({ simcard_fee: res.data.sim_card_fee });
          dispatchShop({ subtotal: res.data.subtotal });
          dispatchShop({ us_tax_rate: res.data.tax_rate });
          dispatchShop({ us_tax_amount: res.data.tax_amount });
          dispatchShop({ total: res.data.total });
          dispatchShop({ us_i_customer: res.data.customer_id });
          dispatchShop({ charge_duration: `${startDateUS} ~ ${endDateUS}` });
          setStep1("completed");
          setStep2("In Progress");
          handleStepChange(2);
          setLoadingNext(false);
        }).catch(err => {
          console.error(err);
          setLoadingNext(false);
        });
      return;
    }

    const payload = {
      orderSimCard: shopDetails["simcard_no"] ? false : true,
      simcard_no: shopDetails["simcard_no"],
      planID: shopDetails["planId"],
      countryId: getServiceCountryId(),
      provinceCode: shopDetails["service_province"],
      start: shopDetails["startDate"],
      end: shopDetails["endDate"],
      sfID: shopDetails["sfID"],
      promocode: shopDetails["prepaidPromocode"],
      esim: shopDetails["esim"]
    };

    instance
      .get("/Activation/GetTotalPrepaid", { params: payload })
      .then((response) => {
        const data = response.data[0];
        dispatchShop({ plan_fee: data.plan_Amt });
        dispatchShop({ simcard_fee: data.simcard_amt });
        dispatchShop({ sfFee: data.shipping_Fee });
        dispatchShop({ prorate_fee: data.prorate_Amt.toFixed(2) });
        dispatchShop({ charge_duration: data.charge_Duration });
        dispatchShop({ gst_rate: data.gst_rate });
        dispatchShop({ pst_rate: data.pst_rate });
        dispatchShop({ gst_amt: data.gst_Amt });
        dispatchShop({ pst_amt: data.pst_Amt });
        dispatchShop({ subtotal: data.subtotal });
        dispatchShop({ promocredit: data.promo_Amt });
        dispatchShop({ total: data.total });
        dispatchShop({ service_countryId: getServiceCountryId() });
        setStep1("completed");
        setStep2("In Progress");
        handleStepChange(2);
        setLoadingNext(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingNext(false);
      });
  };

  const determineUIValue = (variable) => {
    return (shopDetails[variable] && typeof shopDetails[variable] !== "string" && (variable === "startDateLocal" || variable === "endDateLocal"))
      ? shopDetails[variable].format("YYYY-MM-DD")
      : shopDetails[variable];
  };

  const checkCountry = (varToChange) => {
    switch (varToChange) {
      case "stateProvince":
        return t(countryPlan === constants.USA ? "State" : "Province")
      case "zipPostal":
        return t(countryPlan === constants.USA ? "Zip Code" : "Postal Code");
    }
  };

  const onCountrySelect = (country) => {
    setCountryPlan(country.countryName === "United States" ? constants.USA : country.countryName);
    setShippingCountryCodeIso(country.countryCodeIso);
    updateOptionList(country.countryIndex, country.countryName);

    // Reset the form fields related to the address
    dispatchShop({ shipping_unitNo: '' });
    dispatchShop({ shipping_streetAddress: '' });
    dispatchShop({ shipping_city: '' });
    dispatchShop({ shipping_stateProvince: '' });
    dispatchShop({ shipping_zipPostal: '' });

    // shipping fields
    dispatchShop({ shippingStreetAddress: '' });
    dispatchShop({ shippingCity: '' });
    dispatchShop({ shippingProvince: '' });

    // If you are using a state to manage the selected state, reset it as well
  };

  const parseShippingOptionsFromSubscriptionName = (name) => {
    var parts = name.split('_');
    const shippingType = parts[1].toUpperCase();
    let countryCode = parts[2].toUpperCase();
    let country = '';
    switch (countryCode) {
      case 'CA':
        country = constants.CANADA;
        break
      case 'US':
        country = constants.USA;
        break;
      default:
        country = constants.INTERNATIONAL;
        break;
    }

    const fees = +parts[3];
    const minimum = +parts[4];
    const maximum = +parts[5];

    return {
      express: shippingType === 'EX',
      country: country,
      fees: fees,
      minimum: minimum,
      maximum: maximum,
      shippingCountryCode: countryCode,
      shippingType: shippingType
    }
  };

  const updateOptionList = (countryIndex, countryName) => {
    if (shopDetails.country === constants.USA) {
      proxyApi.subscription.getSubscriptionList(new ApiRequest({ name: 'SHIP%' }))
        .then(res => {
          let options = [];
          res.data.subscriptions.forEach((r) => {
            const opt = parseShippingOptionsFromSubscriptionName(r.name);
            options.push({
              key: r.i_subscription,
              optionName: `${(opt.express ? t('Express Shipping') : t('Regular Shipping'))} - ${opt.minimum}~${opt.maximum} ${t('Business Days')}`,
              notice: opt.fees === 0 ? t('Free') : `$${opt.fees}.00 + tax`,
              sfName: opt.express ? 'Express' : 'Regular', // Don't translate this it's not used for the UI other than to show/hide the contact number
              sfFee: opt.fees,
              deliLongest: opt.maximum,
              country: opt.country,
              shippingType: opt.shippingType,
              shippingCountryCode: opt.shippingCountryCode
            });
          });
          switch (countryName) {
            case constants.CANADA:
              options = options.filter(o => o.country === constants.CANADA);
              break
            case "United States":
            case constants.USA:
              options = options.filter(o => o.country === constants.USA);
              break;
            default:
              options = options.filter(o => o.country === constants.INTERNATIONAL);
              break;
          }

          options = options.sort((a, b) => a.sfName > b.sfName ? -1 : 1);
          setDeliveryOptions(options);
          dispatchShop({ shipping_country: countryName });
          dispatchShop({ shipping_countryIndex: countryIndex });
          dispatchShop({ sfID: options[0].key });
          dispatchShop({ sfFee: options[0].sfFee });
          dispatchShop({ sfName: options[0].sfName });
          dispatchShop({ shipping_deliLongest: options[0].deliLongest });
          dispatchShop({ us_account_shipping_code_country: options[0].shippingCountryCode });
          dispatchShop({ us_account_shipping_code_type: options[0].shippingType });
          dispatchShop({ us_shipping_i_subscription: options[0].key });
          console.log('i_subscription', options[0].key);
          console.log('options[0].shippingCountryCode', options[0].shippingCountryCode);
          console.log('options[0].shippingType', options[0].shippingType);
          setContactNumber('');
          resetActivationDate();
        }).catch(err => {
          console.error(err);
        });
      return;
    }


    api.getShippingOptions(countryIndex).then((res) => {
      const options = [];
      res.data.forEach((r) => {
        let realSfName = r.sfName.includes("Express") ? "Express" : "Regular";        
        options.push({
          key: r.sfID,
          optionName: t(r.sfName) + " - " + r.deliEarliest + "~" + r.deliLongest + ` ${t('Business Days')}`,
          notice: r.sfFee === 0 ? "Free" : "$" + r.sfFee + `.00 + ${t('tax')}`,
          sfName: realSfName,
          sfFee: r.sfFee,
          deliLongest: r.deliLongest,
        });
      });

      setDeliveryOptions(options);
      dispatchShop({ shipping_country: countryName });
      dispatchShop({ shipping_countryIndex: countryIndex });
      dispatchShop({ sfID: options[0].key });
      dispatchShop({ sfFee: options[0].sfFee });
      dispatchShop({ sfName: options[0].sfName });
      dispatchShop({ shipping_deliLongest: options[0].deliLongest });
      resetActivationDate();
    }).catch(err => {
      console.error(err);
    });
  };
  

  const valueFormatter = (variable) => {
    if (shopDetails[variable] &&
      typeof shopDetails[variable] === "string" &&
      (variable === "startDateLocal")) {
      return moment(shopDetails[variable]).format("YYYY-MM-DD");
    }

    if (variable === "simcard_fee"
      || variable === "prorate_fee"
      || variable === "sfFee"
      || variable === "promocredit"
      || variable === "plan_fee"
      || variable === "subtotal"
      || variable === "total") {
      return (shopDetails[variable] && typeof shopDetails[variable] === "number") ? shopDetails[variable].toFixed(2) : "0.00";
    }

    return shopDetails[variable];
  };


  const handleCanadianPortin = (event) => {
    let newPortinNumber = event.target.value.replace(/[^\d.-]+/g, '');
    setCanadianPortinNumber(newPortinNumber);

    // Quebec area codes need to be ignored
    if (shopDetails.country === constants.CANADA && shopDetails.planCarrier.toLowerCase() === "rogers") {
      if (quebecAreaCodes.some(areaCode => newPortinNumber.startsWith(areaCode) || newPortinNumber.startsWith("1" + areaCode))) {
        setInvalidCanadianPortin(true);
        return;
      }
    }
    setInvalidCanadianPortin(false);
    dispatchShop({ portin_phoneNo: newPortinNumber });
    dispatchShop({ portin_phoneNo_local: newPortinNumber });
  }

  const validateEmailAddress = (email) => {
    if (shopDetails.country === constants.USA) {
      setErrorMessage('');
      setErrorMsgs({ ...errorMsgs, emailError: "" });

      if (!validateEmail(email)) {
        setEmailExistError(true);
        setErrorMsgs({ ...errorMsgs, emailError: t("Your email address must contain only latin letters, digits, '-', '_', '@', and '.'.") });
        return;
      };

      // Used to test
      // ccc@gmail.com exists
      proxyApi.account.getAccountList(new ApiRequest({
        email: email,
        get_not_closed_accounts: 1
      })).then(res => {
        if (res.data.account_list.length > 0) {
          setEmailExistError(true);
          setErrorMsgs({ ...errorMsgs, emailError: "Your email address is already in use!" });
        } else {
          setEmailExistError(false);
          setErrorMsgs({ ...errorMsgs, emailError: "" });
        }
      }).catch(err => {
        console.error("validateEmailAddress() /Account/get_account_list", err);
      });
      return;
    }

    // Email confirmation API - new as of July 19, 2022
    // Used to test
    // JINGRONG0164@SINA.COM
    // 89302720554010901626

    let bn = new URLSearchParams(window.location.search).get("bn");
    const url = `/Activation/CheckIfEmailExisting?email=${email}&bizid=${bn ? bn : 0}&simnum=${shopDetails.simcard_no !== "" ? shopDetails.simcard_no : "''"}`;;
    instance
      .post(url)
      .then((response) => {
        let data = response.data;
        if (data) {
          setEmailExistError(true);
          setErrorMsgs({ ...errorMsgs, emailError: "Your email address is already in use!" });
        } else {
          setEmailExistError(false);
          setErrorMsgs({ ...errorMsgs, emailError: "" });
        }
      })
      .catch((error) => {
        console.error("validateEmailAddress() /Activation/CheckIfEmailExisting", error.response.data);
      });
  };

  const calculateCalendarDates = (current) => {
    // the customDate is the date that we can't ship before this.
    // if shipping is requred, we add the estimated longest shipping date.
    let customDate = shopDetails.delivery_needed
      ? moment().add(Number(shopDetails.shipping_deliLongest), "days").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    // // checking EST dates only for USA
    // if (shopDetails.country === constants.USA) {
    //   // console.log(current.hours());
    //   let currentHours = current.hours();

    //   // custom date offset in hours
    //   let cdOffset = moment(customDate).utcOffset()/60;
    //   let utcHours = currentHours - cdOffset;

    //   // find the offset in EST timezone (-4 UTC)
    //   let estHours = utcHours - 4

    //   // console.log('estHours: ', estHours);

    //   // if it's after midnight in EST, change to the next day.
    //   if (estHours >= 24) {
    //     customDate = moment(customDate).add(1, "days").format("YYYY-MM-DD");
    //   }
    // }

    // if (isTelusPlan) {
    //   return undefined;
    // }

    let returnVal;

    // changes not needed for US portin. all done by API
    if (checkoutDetails.portInStatus && shopDetails.country === constants.CANADA && !isTelusPlan) {
      //filter out the days before today and Sat. Sun..
      returnVal =
        (current && current < moment(customDate, "YYYY-MM-DD"))
        || moment(current).day() === 0
        || moment(current).day() === 6;

      // filter out holidays
      const holidays = ["2024-01-01", "2024-02-19", "2024-03-29", "2024-05-20", "2024-07-01", "2024-08-05", "2024-09-02", "2024-09-30", "2024-10-14", "2024-11-11", "2024-12-25"];
      holidays.map((day) => {
        returnVal = returnVal || moment(current).format("YYYY-MM-DD") === day;
      });
    } else {
      returnVal = current && current < moment(customDate, "YYYY-MM-DD");
    }
    return returnVal;
  };

  return (
    <PageLayout>
      <title>Purchase | PhoneBox</title>
      {(showProratedPlanModal && step == 2) && (
        <ProratedPlanModal setShowModal={setShowProratedPlanModal} />
      )}
      {mobile ? (
        <>
          <div style={{ padding: "2rem 1rem" }}>
            {step1 === "In Progress" ? (
              <BackArrow
                onClick={() => {
                  goTo("/plan-info");
                }}
                style={{
                  color: "#000",
                  fontWeight: 500,
                  fontSize: 20,
                  marginLeft: "2rem",
                }}
              >
                {"<"} <Trans>Back</Trans>
              </BackArrow>
            ) : (
              <div style={{ color: "#000", fontWeight: 500, fontSize: 20 }}
                onClick={() => {
                  setErrorMessage('');
                  setUSCheckoutError('');
                  handleStepChange(step - 1, "enable");
                  setStep1("In Progress");
                  setStep2("pending");
                }}
              >
                {"<"} <Trans>Back</Trans>
              </div>
            )}
            <PaymentProcedure step1={step1} step2={step2} step3={"pending"} />
          </div>
        </>
      ) : (
        <PageHeader text={step === 1 ? t("Personal Information") : t("Review")} imageSrc="/images/activation/activation-header-icon.png" />
      )}
      <Container style={{ paddingBottom: "60px" }}>
        <Row className={step === 3 && "d-none"}>
          <Col sm={12} md={7}>
            <div className={step === 1 ? "d-block" : "d-none"}>
              {isCanada && !mobile ? (
                <Box className={routeDetails.routeString === "activation" ? "d-none" : "d-block"} style={{ backgroundColor: "#ECFAFF" }}>
                  <Row>
                    <Col>
                      <UnderlineTitleBlue>
                        <Trans>eSIM</Trans>
                      </UnderlineTitleBlue>
                    </Col>
                    <Col>
                      <a data-elevio-article="60">
                        <EsimQuestion>
                          <Trans>What's an eSIM?</Trans>
                        </EsimQuestion>
                      </a>
                    </Col>
                  </Row>
                  {shopDetails.esim ? (
                    <Label>
                      <Trans>Purchasing eSIM!</Trans>
                    </Label>
                  ) : (
                    <Label>
                      <Trans>Do you want an eSIM?</Trans>{" "}
                      <a onClick={() => goTo('/plans')}>
                        <Trans>Search the plans page for eSIM!</Trans>
                      </a>
                    </Label>
                  )}
                </Box>
              ) : null}
              <Box>
                <UnderlineTitleBlue>
                  <Trans>Personal Information</Trans>
                </UnderlineTitleBlue>
                <Row>
                  <Col sm={12} md={6} className="p-3">
                    <RequiredLabel>
                      <Trans>First Name</Trans>
                    </RequiredLabel>
                    <Form.Control type="text" onChange={(event) => dispatchShop({ firstName: event.target.value })} />
                  </Col>
                  <Col sm={12} md={6} className="p-3">
                    <RequiredLabel>
                      <Trans>Last Name</Trans>
                    </RequiredLabel>
                    <Form.Control type="text" onChange={(event) => dispatchShop({ lastName: event.target.value })} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className="p-3 mt-auto">
                    <RequiredLabel>
                      <Trans>Email Address</Trans>
                    </RequiredLabel>
                    <Form.Control type="text" onChange={(event) => {
                      const email = event.target.value;
                      dispatchShop({ email: event.target.value });
                      compareEmailAddress(email, shopDetails.emailConfirm);
                    }} />
                  </Col>
                  <Col sm={12} md={6} className="p-3">
                    <RequiredLabel>
                      <Trans>Confirm Email Address</Trans>
                    </RequiredLabel>
                    <Form.Control type="text" onChange={(event) => {
                      const emailConfirm = event.target.value;
                      dispatchShop({ emailConfirm: emailConfirm });
                      compareEmailAddress(shopDetails.email, emailConfirm);
                    }} onPaste={(e) => { e.preventDefault(); return false; }} />
                  </Col>
                  <Col sm={12} md={6}>
                    <ErrorText>
                      <Trans>{errorMsgs.emailError}</Trans>
                    </ErrorText>
                  </Col>
                </Row>
                {isCanada &&
                  <Row>
                    <Col className="p-3">
                      <RequiredLabel>
                        <Trans>Where will you be using our service?</Trans>
                      </RequiredLabel>
                      <Row>
                        <Col lg={6} className="p-3">
                          <Dropdown>
                            <Dropdown.Toggle variant="primary" className="w-100">
                              {shopDetails.service_province_fullname}
                            </Dropdown.Toggle>
                            {isCanada &&
                              <Dropdown.Menu>
                                {provinces.map(p => { 
                                  // don't show QC if it's not a Telus plan
                                  if (isTelusPlan || p.code !== "QC") {
                                    return (
                                      <Dropdown.Item key={p.key} onClick={() => handleProvinceMenuClick(p)}>
                                        <Trans>{p.name}</Trans>
                                      </Dropdown.Item>
                                    )
                                  }
                                })}
                              </Dropdown.Menu>
                            }
                            {shopDetails.country === constants.USA &&
                              <Dropdown.Menu>
                                {states.map(s => (
                                  <Dropdown.Item key={s.key} onClick={() => handleStateMenuClick(s)}>
                                    <Trans>{s.name}</Trans>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            }
                          </Dropdown>
                        </Col>
                        {shopDetails.country === constants.CANADA &&
                          <Col lg={6} className="p-3">
                            <Form.Control type="text" placeholder={t("Enter a city")} onChange={handleCityChange} />
                          </Col>
                        }
                        {shopDetails.country === constants.USA && shopDetails.service_province_fullname === "I'm not sure" &&
                          <p><Trans>No worries, we will assign a Delaware number to you.</Trans></p>
                        }
                      </Row>
                    </Col>
                  </Row>
                }
              </Box>
              <Box>
                <UnderlineTitleBlue>
                  <Trans>Port In</Trans>
                </UnderlineTitleBlue>
                <Row>
                  <Col>
                    <AppYesNo
                      label={`Do you want to port in your number? (${(shopDetails.country === constants.CANADA ? 'Canadian' : 'US')} numbers only)`}
                      name="portInRadioBtnsGroup"
                      onChange={(value) => handlePortIn(value)}
                      required={true} />
                  </Col>
                </Row>
                {shopDetails.country === constants.USA && (
                  <>
                    {checkoutDetails.portInStatus &&
                      <Row>
                        <Col>
                          <AppYesNo
                            label="Is your number a wireless number?"
                            name="isWirelessRadioBtnsGroup"
                            onChange={(value) => handlePortInIsWireless(value)}
                            required={true} />
                          {!checkoutDetails.usPortInWireless &&
                            <small className="d-block text-danger">
                              <Trans>Sorry, we are not able to port in the landline number at this moment.</Trans>
                            </small>
                          }
                        </Col>
                      </Row>
                    }
                    {checkoutDetails.portInStatus && checkoutDetails.usPortInWireless &&
                      <Row>
                        <Col sm={6}>
                          <Label>
                            <Trans>Please enter your port in number:</Trans>
                          </Label>
                          <InputGroup className="mt-2">
                            <Form.Control type="tel" value={usaPhoneNumber} onChange={handleUSAPhoneNumber} maxLength={10} />
                            <Button variant="primary" onClick={() => handlePortInVerify()} disabled={usaPhoneNumber.length !== 10 || usaPortInVerifying}>
                              {!usaPortInVerifying && <Trans>Verify</Trans>}
                              {usaPortInVerifying &&
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Verifying...</span>
                                  </div>
                                </div>
                              }
                            </Button>
                          </InputGroup>
                          {!checkoutDetails.usPortInVerified && usaPortinVerifyError.length > 0 &&
                            <small className="text-danger mt-2">
                              <Trans>{usaPortinVerifyError}</Trans>
                            </small>
                          }
                        </Col>
                      </Row>
                    }
                    {checkoutDetails.usPortInVerified && checkoutDetails.usPortInWireless &&
                      <>
                        <hr className="mt-4 w-25" />
                        <Row className="mt-4">
                          <Col xs={12}>
                            <Label>
                              <Trans>Provide more details about your current phone number.</Trans>
                            </Label>
                            <small className="mb-0"><Trans>Please contact your current provider to get the account number, PIN number, and the services address that is associated with your account.</Trans></small>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col xs={12} md={4}>
                            <Label>
                              <Trans>Phone Number:</Trans>
                            </Label>
                            <Form.Control value={usaPhoneNumber} disabled={true} />
                          </Col>
                          <Col xs={12} md={4}>
                            <Label>
                              <Trans>Account Number:</Trans>
                            </Label>
                            <Form.Control type="text" value={usaAccountNumber} onChange={handleUSAAccountNumber} maxLength={20} />
                          </Col>
                          <Col xs={12} md={4}>
                            <Label>
                              <Trans>PIN Number:</Trans>
                            </Label>
                            <Form.Control type="text" value={usaPINNumber} onChange={handleUSAPINNumber} maxLength={15} />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col xs={12}>
                            <Label>
                              <Trans>Service Address:</Trans>
                            </Label>
                            <small className="mb-0"><Trans>The service address must be the same as your current services provider's invoice, otherwise, the port in may not succeed.</Trans></small>
                          </Col>
                        </Row>

                        <Row >
                          <Col xs={12}>
                            <div className="my-4">
                              <SwitchButton width={120} height={50} textOn="Manual" textOff="Auto" onSwitch={() => setIsServiceAddressAutoCompleteFinished(!isServiceAddressAutoCompleteFinished)} />
                            </div>
                          </Col>
                        </Row>

                        {!isServiceAddressAutoCompleteFinished &&
                          <div>
                            <Row>
                              <Col xs={12}>
                                {/* <GoogleAutoCompleteAddress autoCompleteType="address" onAutoCompleteAddress={getServiceAddressAutoComplete} country={shopDetails.country === constants.USA ? 'us' : 'ca'} /> */}
                                <CanadaPostAutoCompleteAddress onAutoCompleteAddress={getServiceAddressAutoComplete} selectedCountry={'us'}/>
                              </Col>
                            </Row>
                          </div>
                        }
                        {isServiceAddressAutoCompleteFinished &&
                          <>
                            <Row>
                              {/* <Col md={3} sm={6} className="p-3">
                                <Label bottomGap="16px">
                                  <Trans>Unit No</Trans>
                                </Label>
                                <Form.Control type="text" onChange={(event) => dispatchShop({ us_portin_service_unitNo: event.target.value })} />
                              </Col> */}
                              <Col md={12} sm={12} className="p-3">
                                <RequiredLabel>
                                  <Trans>Unit No & Street Address</Trans>
                                </RequiredLabel>
                                <Form.Control type="text" onChange={(event) => dispatchShop({ us_portin_service_street_address: event.target.value })} value={shopDetails.us_portin_service_street_address} />
                              </Col>
                              <Col md={6} sm={12} className="p-3">
                                <RequiredLabel>
                                  <Trans>City</Trans>
                                </RequiredLabel>
                                <Form.Control type="text" onChange={(event) => dispatchShop({ us_portin_service_city: event.target.value })} value={shopDetails.us_portin_service_city} />
                              </Col>
                              <Col md={6} sm={12} className="p-3">
                                <RequiredLabel>
                                  {checkCountry("stateProvince")}
                                </RequiredLabel>
                                <StatesDropDownList value={usPortinState} onSelect={(state) => { setUSPortinState(state); dispatchShop({ us_portin_service_state_province: state.code }) }}></StatesDropDownList>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={12} className="p-3">
                                <RequiredLabel>
                                  <Trans>Country</Trans>
                                </RequiredLabel>
                                <CountriesDropDownList onSelect={(event) => dispatchShop({ us_portin_service_country: event.countryName })} defaultValue={shopDetails.us_portin_service_country} onlyUSA={true} />
                              </Col>
                              <Col md={6} sm={12} className="p-3">
                                <RequiredLabel>
                                  {checkCountry("zipPostal")}
                                </RequiredLabel>
                                <Form.Control type="text" onChange={(event) => dispatchShop({ us_portin_service_zip_postal: event.target.value })} value={shopDetails.us_portin_service_zip_postal} />
                              </Col>
                            </Row>
                          </>
                        }
                        <Row className="mt-4">
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex justify-content-start">
                                <div className="form-check" role="button">
                                  <input className="form-check-input" id="notAPOBox" type="checkbox" onChange={handleNotAPOBox} checked={notAPOBox} />
                                  <label className="form-check-label" htmlFor="notAPOBox">
                                    <Trans>This is not a P.O. box</Trans>
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    }
                  </>
                )}
                {shopDetails.country === constants.CANADA && (
                  <div className={checkoutDetails.portInStatus ? "d-block" : "d-none"}>
                    <TextSmall className="my-4">
                      <Trans>
                        Provide more details about your current phone number.
                      </Trans>
                    </TextSmall>
                    <TextSmall>
                      <Trans>You must inform your current provider that you wish to port out or you will be unable to port out your phone number.</Trans>
                    </TextSmall>
                    <TextSmallBold>
                      <Trans>Notice for port in customers: the activation is not available during the weekends and holidays.</Trans>
                    </TextSmallBold>
                    {needInteractionID() && (
                      <TextSmall className="my-4">
                        <Trans>Ownership Transfer from Rogers to PhoneBox Rogers: If you wish to transfer from your current Rogers phone number to PhoneBox service, please leave an authorization note to CONNEX GLOBAL COMMUNICATIONS INC on your individual Rogers account and provide us your reference number of the process(Interaction ID) from Rogers.</Trans>
                      </TextSmall>
                    )}
                    <Row>
                      <Col md={6} sm={12} className="p-3">
                        <RequiredLabel>
                          <Trans>Current Carrier</Trans>
                        </RequiredLabel>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary">
                            {shopDetails.portin_carrier_local}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {carriers.map(c => (
                              <Dropdown.Item key={c.key} onClick={() => handleCarrierMenuClick(c)}>
                                <Trans>{c.name}</Trans>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {invalidCarrier && <CarrierErrorMsg>
                          <Trans>
                            Sorry, the phone number you've entered is not eligible for Port In.
                          </Trans>
                        </CarrierErrorMsg>}
                        {isFreedomPortin && <CarrierErrorMsg>
                          <Trans>
                            Please provide the  IMEI (15 digits) to port in your number.
                          </Trans>
                          <br />
                          <Trans>
                            Dial *#06# to get your phone's IMEI number or find it in your phone's settings.
                          </Trans>
                        </CarrierErrorMsg>}
                      </Col>
                      <Col md={6} sm={12} className="p-3">
                        {isFreedomPortin ? (
                          <>
                            <RequiredLabel>
                              <Trans>IMEI Number (15 digits)</Trans>
                            </RequiredLabel>
                            <Form.Control maxLength={15} minLength={15} disabled={invalidCarrier} type="text" onChange={(event) => {
                              dispatchShop({ portin_accountNo: event.target.value });
                              dispatchShop({ portin_accountNo_local: event.target.value });
                            }} />
                          </>
                        ) : (
                          <>
                            <RequiredLabel>
                              <Trans>Account Number</Trans>
                            </RequiredLabel>
                            <Form.Control disabled={invalidCarrier} type="text" onChange={(event) => {
                              dispatchShop({ portin_accountNo: event.target.value });
                              dispatchShop({ portin_accountNo_local: event.target.value });
                            }} />
                          </>
                        )}
                      </Col>
                      <Col md={6} sm={12} className="p-3">
                        <RequiredLabel>
                          <Trans>Phone Number</Trans>
                        </RequiredLabel>
                        <Form.Control disabled={invalidCarrier} type="text" value={canadianPortinNumber} onChange={(event) => {
                          handleCanadianPortin(event);
                        }} />
                        {invalidCanadianPortin && <CarrierErrorMsg>
                          We're sorry, port-in from Quebec is not available.
                        </CarrierErrorMsg>}
                      </Col>
                      {needInteractionID() ? (
                        <Col md={6} sm={12} className="p-3">
                          <RequiredLabel>
                            <Trans>Interaction ID</Trans>
                          </RequiredLabel>
                          <Form.Control type="text" onChange={(event) => {
                            dispatchShop({ portin_other: event.target.value });
                            dispatchShop({ portin_other_local: event.target.value });
                          }} />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                )}
              </Box>
              {shopDetails.delivery_needed &&
                <Box className={routeDetails.routeString === "activation" || shopDetails.delivery_needed ? "d-block" : "d-none"}>
                  <UnderlineTitleBlue>
                    <Trans>Shipping</Trans>
                  </UnderlineTitleBlue>
                  <Row>
                    <Col md={12} sm={12} className="p-3">
                      <SwitchButton width={120} height={50} textOn="Manual" textOff="Auto" onSwitch={() => setIsAutoCompleteFinished(!isAutoCompleteFinished)} />
                    </Col>
                  </Row>
                  {!isAutoCompleteFinished && (
                    <>
                      <Row>
                        <RequiredLabel>
                              <Trans>Country</Trans>
                            </RequiredLabel>
                            <CountriesDropDownList onSelect={onCountrySelect} defaultValue={shopDetails.shipping_country} />
                      </Row>
                      <Row>
                        <Col md={12} sm={12} className="p-3">
                          <Label bottomGap="16px">
                            <Trans>Address</Trans>
                          </Label>
                          {
                            (shopDetails.shipping_country === constants.CANADA || shopDetails.shipping_country === constants.USA) ?
                              <CanadaPostAutoCompleteAddress 
                                onAutoCompleteAddress={getShippingAddressAutoComplete} 
                                selectedCountry={shippingCountryCodeIso === '' ? shopDetails.shipping_country : shippingCountryCodeIso} 
                              />
                              :
                              <GoogleAutoCompleteAddress 
                                autoCompleteType="address" 
                                onAutoCompleteAddress={getShippingAddressAutoComplete}
                                country={shippingCountryCodeIso} 
                              />
                          }
                        </Col>
                      </Row>
                    </>
                    
                  )}
                  {isAutoCompleteFinished &&
                    <>
                      <Row>
                        {/* <Col md={3} sm={6} className="p-3">
                          <Label bottomGap="16px">
                            <Trans>Unit No</Trans>
                          </Label>
                          <Form.Control type="text" onChange={(event) => dispatchShop({ shipping_unitNo: event.target.value })} />
                        </Col> */}
                        <Col md={12} sm={12} className="p-3">
                          <RequiredLabel>
                            <Trans>Unit No & Street Address</Trans>
                          </RequiredLabel>
                          <Form.Control type="text" onChange={(event) =>  { 
                            dispatchShop({ shipping_streetAddress: event.target.value }) 
                            dispatchShop({ shippingStreetAddress: event.target.value })
                          }} value={shopDetails.shipping_streetAddress} />
                        </Col>
                        <Col md={6} sm={12} className="p-3">
                          <RequiredLabel>
                            <Trans>City</Trans>
                          </RequiredLabel>
                          <Form.Control type="text" onChange={(event) =>  { 
                            dispatchShop({ shipping_city: event.target.value }) 
                            dispatchShop({ shippingCity: event.target.value }) 
                          }} value={shopDetails.shipping_city} />
                        </Col>
                        <Col md={6} sm={12} className="p-3">
                          <RequiredLabel>
                            {checkCountry("stateProvince")}
                          </RequiredLabel>
                          <Form.Control type="text" onChange={(event) => { 
                            dispatchShop({ shipping_stateProvince: event.target.value }) 
                            dispatchShop({ shippingProvince: event.target.value })
                          }} value={shopDetails.shipping_stateProvince} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={12} className="p-3">
                          <RequiredLabel>
                            <Trans>Country</Trans>
                          </RequiredLabel>
                          <CountriesDropDownList onSelect={onCountrySelect} defaultValue={shopDetails.shipping_country} />
                        </Col>
                        <Col md={6} sm={12} className="p-3">
                          <RequiredLabel>
                            {checkCountry("zipPostal")}
                          </RequiredLabel>
                          <Form.Control type="text" onChange={(event) => dispatchShop({ shipping_zipPostal: event.target.value })} value={shopDetails.shipping_zipPostal} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label bottomGap="16px" topGap="16px">
                            <Trans>Shipping Option</Trans>
                          </Label>
                          <RadioList
                            optionList={deliveryOptions}
                            width={mobile ? "100%" : "80%"}
                            spanColor="#0594ca"
                            name="deliverySelect"
                            hideIndex={
                              shopDetails.shipping_country === constants.CANADA ||
                                shopDetails.shipping_country === ""
                                ? NO_HIDING
                                : ONE_DAY_DELIVERY
                            }
                            onSelect={(option) => {
                              dispatchShop({ sfID: option.key });
                              dispatchShop({ sfFee: option.sfFee });
                              dispatchShop({ sfName: option.sfName });
                              dispatchShop({ shipping_deliLongest: option.deliLongest });
                              if (shopDetails.country === constants.USA) {
                                dispatchShop({ us_shipping_i_subscription: option.key });
                                dispatchShop({ us_account_shipping_code_country: option.shippingCountryCode });
                                dispatchShop({ us_account_shipping_code_type: option.shippingType });
                                if (option.shippingType === 'RE') {
                                  setContactNumber('');
                                }
                                console.log('i_subscription on select', option.key);
                                console.log('options[0].shippingCountryCode on select', option.shippingCountryCode);
                                console.log('options[0].shippingType on select', option.shippingType);
                              }
                              resetActivationDate();
                            }}
                          />
                        </Col>
                      </Row>
                      {shopDetails.sfName === 'Express' &&
                        <Row>
                          <Col md={6} sm={12} className="p-3">
                            <RequiredLabel>
                              <Trans>Contact Number</Trans>
                            </RequiredLabel>
                            <Form.Control type="tel" value={contactNumber} onChange={handleContactNumber} />
                          </Col>
                        </Row>
                      }
                    </>
                  }
                </Box>
              }
              <Box>
                <UnderlineTitleBlue>
                  <Trans>Activation</Trans>
                </UnderlineTitleBlue>
                <RequiredLabel>
                  <Trans>Activation Date</Trans>
                </RequiredLabel>
                <div className="mb-4">
                  <ConfigProvider locale={locale}>
                    <DatePicker
                        locale={locale}
                        id="activationDatePicker"
                        value={checkoutDetails.activationDateObject}
                        style={{ width: mobile ? "100%" : "200px", padding: "10px" }}
                        disabledDate={calculateCalendarDates}
                        onChange={(val) => handleActivationDateChange(val)}
                      />
                  </ConfigProvider>
                </div>
                {shopDetails.delivery_needed && (
                  <TextSmall>
                    <Trans>You are unable to activate before you have received your SIM Card. Your SIM Card should be arriving on or before the date above.</Trans>
                  </TextSmall>
                )}
              </Box>
              <div className="text-center mb-5">
                <CustomButton disabled={loadingNext} onClick={handleNext}                >
                  {loadingNext && <Spinner variant="light" className="m-auto" animation="border" size="sm" />}
                  <span className="ms-2">
                    <Trans>Next</Trans>
                  </span>
                </CustomButton>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </div>
            </div>
            <div className={step === 2 ? "d-block" : "d-none"}>
              <SummaryBox mobile={mobile ? mobile : false}>
                <Row>
                  {mobile ? (
                    <>
                      <p
                        style={{
                          color: "#0494CA",
                          fontSize: "1.5rem",
                          fontWeight: 700,
                          paddingLeft: "10px",
                        }}
                      >
                        <Trans>Summary</Trans>
                      </p>
                      <Row>
                        <Label>
                          <Trans>Name</Trans>:
                        </Label>
                      </Row>
                      <Row>
                        <Price>
                          {`${shopDetails.firstName} ${shopDetails.lastName}`}
                        </Price>
                      </Row>
                      <Row>
                        <Label>
                          <Trans>Email</Trans>:
                        </Label>
                      </Row>
                      <Row>
                        <Price>{shopDetails.email}</Price>
                      </Row>
                      <Row>
                        <Label>
                          <Trans>Activation Date</Trans>:
                        </Label>
                      </Row>
                      <Row>
                        <Price>{determineUIValue("startDateLocal")}</Price>
                      </Row>
                    </>
                  ) : (
                    <>
                      <p style={{ color: "black", fontSize: "1.25em", fontWeight: 700, }}>
                        <Trans>Summary</Trans>
                      </p>
                      <Row>
                        <Label>
                          <Trans>Name</Trans>:
                        </Label>
                      </Row>
                      <Row>
                        <Price>
                          {`${shopDetails.firstName} ${shopDetails.lastName}`}
                        </Price>
                      </Row>
                      <Row>
                        <Label>
                          <Trans>Email</Trans>:
                        </Label>
                      </Row>
                      <Row>
                        <Price>{shopDetails.email}</Price>
                      </Row>
                      <Row>
                        <Label>
                          <Trans>Activation Date</Trans>:
                        </Label>
                      </Row>
                      <Row>
                        <Price>{determineUIValue("startDateLocal")}</Price>
                      </Row>
                    </>
                  )}
                  <br />
                  <br />
                  <br />
                  {mobile ? (
                    <Col sm={12} md={5}>
                      <Box margin={"40px 0px"}>
                        <Row>
                          <Col sm={12} md={9}>
                            <SimCardDetail planId={shopDetails.planId} disableEdit={disableEditBar} imgDisplay={false} disableChangePlanBtn={disableChangeBtn} />
                          </Col>
                        </Row>
                        <div className="pt-4">
                          <hr />
                        </div>
                        <Title className="mt-4" style={{ color: "black" }} ></Title>
                        {shopDetails.country === constants.CANADA && (
                          <Ul left="0" padding="0">
                            <Li size="17px">
                              <LiCont>
                                <Trans>UNLIMITED calling and messaging</Trans>
                              </LiCont>
                            </Li>
                            <Li>
                              <LiCont>
                                <Trans>
                                  UNLIMITED International text and picture messaging
                                </Trans>
                              </LiCont>
                            </Li>
                            {isTelusPlan && <Li>
                              <LiCont>
                                <Trans>
                                  UNLIMITED international calling
                                </Trans>
                              </LiCont>
                            </Li>}
                            {!isTelusPlan &&
                              <Li>
                                <LiCont>
                                  <Trans>
                                    Supported on the {shopDetails.planCarrier} Network
                                  </Trans>
                                </LiCont>
                              </Li>
                            }
                            <Li>
                              <LiCont>
                                <Trans>Call Display</Trans>
                              </LiCont>
                            </Li>
                            <Li>
                              <LiCont>
                                <Trans>
                                  Voicemail - Up to 35 minutes of messages
                                </Trans>
                              </LiCont>
                            </Li>
                            {!shopDetails.noTopUpFee && (shopDetails.data + "" !== "Unlimited") && <Li>
                              <LiCont>
                                <Trans>
                                  Data {shopDetails.isPostpaid ? "Overage" : "TopUp"} Rate: $15.00 / 1GB
                                </Trans>
                              </LiCont>
                            </Li>}
                          </Ul>
                        )}

                        {shopDetails.country !== constants.CANADA &&
                          planIncludesDescription[shopDetails.planId] && (
                            <Ul left="0" padding="0">
                              {planIncludesDescription[shopDetails.planId].map(
                                (entry, i) => {
                                  if (entry.includes("75+ Countries")) {
                                    let index = entry.indexOf("75+ Countries");
                                    let firstPart = entry.substring(0, index);
                                    return (
                                      <Li size="20px" key={i}>
                                        <Trans>{firstPart}</Trans>
                                        <span style={{ color: "#22C3F1" }} data-elevio-article="82" className="_elevio_underline ctxUnderline" data-elevio-unique-id="8">
                                          75+ <Trans>Countries</Trans>
                                        </span>
                                      </Li>
                                    );
                                  }
                                  return (
                                    <Li size="20px">
                                      <Trans>{entry}</Trans>
                                    </Li>
                                  );
                                }
                              )}
                            </Ul>
                          )}
                      </Box>
                    </Col>
                  ) : null}
                  <p
                    style={{
                      color: mobile ? "#0494CA" : "black",
                      fontSize: mobile ? "1.5rem" : "1.25em",
                      fontWeight: 700,
                      marginBlockStart: "revert",
                      paddingLeft: "10px",
                    }}
                  >
                    <Trans>Item Details</Trans>
                  </p>
                  <Row>
                    <Col>
                      <Label>
                        <Trans>SIM Card Fee</Trans>
                      </Label>
                    </Col>
                    <Col>
                      {!shopDetails.esim ? (
                        <Price details={"80px"}>
                          ${valueFormatter("simcard_fee")}
                        </Price>
                      ) : (
                        <Price details={"80px"}>
                          $10.00
                        </Price>
                      )}
                    </Col>
                  </Row>
                  {shopDetails.esim &&
                    <Row>
                      <Col>
                        <Label>
                          <Trans>eSIM Promotion</Trans>
                        </Label>
                      </Col>
                      <Col>
                        <Price details={"80px"}>
                          -$10.00
                        </Price>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col>
                      <Label>
                        <Trans>Shipping Fee</Trans>
                      </Label>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${shopDetails.sfFee.toFixed(2)}
                      </Price>
                    </Col>
                  </Row>

                  {shopDetails.isPostpaid ? (
                    <Row>
                      <Col>
                        <Label>
                          <Trans>Prorated Fee</Trans> <br />
                          <span style={{ color: "#AEAFB1", fontSize: mobile ? ".5rem" : "0.8em", }}>
                            {shopDetails.prorate_duration}
                          </span>
                        </Label>
                      </Col>
                      <Col>
                        <Price details={"80px"}>
                          ${shopDetails.prorate_fee}
                        </Price>
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <Col>
                      <Label>
                        <Trans>{shopDetails.isPostpaid ? "First Whole Month" : "Plan Fee"}</Trans>{" "}
                        <br />
                        <span style={{ color: "#AEAFB1", fontSize: mobile ? ".5rem" : "0.8em" }}>
                          {shopDetails.charge_duration}
                        </span>
                      </Label>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${shopDetails.plan_fee.toFixed(2)}
                      </Price>
                    </Col>
                  </Row>
                  {shopDetails.promocredit > 0 ? (
                    <Row>
                      <Col>
                        <Label>
                          <Trans>{shopDetails.isPostpaid ? shopDetails.promocode + " Promotion Credit" : shopDetails.prepaidPromocode + " Promotion Credit"}</Trans>
                        </Label>
                      </Col>
                      <Col>
                        <PromoPrice details={"66px"}>
                          - ${shopDetails.promocredit.toFixed(2)}
                        </PromoPrice>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Label>
                          <Trans>Other</Trans>
                        </Label>
                      </Col>
                      <Col>
                        <Price details={"80px"}>
                          ${shopDetails.promocredit.toFixed(2)}
                        </Price>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Label>
                        <Trans>Sub Total</Trans>
                      </Label>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${shopDetails.subtotal ? shopDetails.subtotal.toFixed(2) : "0.00"}
                      </Price>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>
                        {shopDetails.country === constants.USA ? (<Trans>Taxes & Surcharges</Trans>) : (<Trans>Taxes</Trans>)}
                        {shopDetails.country === constants.CANADA ? `(${getTaxRate()})%` : ''}
                      </Label>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${getTaxAmount()}
                      </Price>
                    </Col>
                  </Row>
                  <hr style={{ marginRight: "20%", width: mobile ? "3rem" : "100%", marginLeft: mobile ? "14px" : 0, marginTop: mobile ? "10px" : 0 }} />
                  <Row>
                    <Col>
                      <Label style={{ fontSize: "18px", fontWeight: 500 }}>
                        <Trans>Total</Trans>
                      </Label>
                    </Col>
                    <Col>
                      <Price style={{ fontSize: "18px", fontWeight: 500 }} details={"80px"}>
                        ${determineUIValue("total").toFixed(2)}
                      </Price>
                    </Col>
                  </Row>
                </Row>
              </SummaryBox>
              {shopDetails.country !== constants.USA && 
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: "80px", paddingRight: "80px", marginBottom: 20 }}>
                    {cemChecked ? (
                      <FontAwesomeIcon style={{ color: '#333333' }} icon={faSquareCheck} fontSize={32} onClick={() => setCemChecked(false)}></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon style={{ color: '#333333' }} icon={faSquare} fontSize={32} onClick={() => setCemChecked(true)}></FontAwesomeIcon>
                    )}
                    {isEastAsianLanguage ? (
                      <div style={{ fontSize: '12px', paddingLeft: 10 }}>
                         <span onClick={() => goTo(termsLink)} style={{ color: '#01bae5', cursor: 'pointer', textDecorationLine: "underline" }}>terms & conditions</span><Trans>I have looked at and read PhoneBox’s Terms & Conditions and agree to the terms.</Trans>
                      </div>
                    ) : (
                      <div style={{ fontSize: '12px', paddingLeft: 10 }}>
                        <Trans
                          defaults="I have read and understand the <span1>terms & conditions</span1> and agree to receive personalized commercial communications from PhoneBox."
                          components={{
                            span1: <span onClick={() => goTo(termsLink)} style={{ color: '#01bae5', cursor: 'pointer', textDecorationLine: "underline" }} />
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {cemError && <CarrierErrorMsg>
                    <Trans>
                      Please agree to the terms and conditions.
                    </Trans>
                  </CarrierErrorMsg>}
                </>
              }
              <div className="text-center">
                <CustomButton
                  className="mx-3"
                  onClick={() => {
                    setErrorMessage('');
                    setUSCheckoutError('');
                    handleStepChange(step - 1, "enable");
                    setStep1("In Progress");
                    setStep2("pending");
                  }}
                >
                  <Trans>Back</Trans>
                </CustomButton>
                <CustomButton className="mx-3" onClick={checkout} disabled={loadingNext}>
                  {loadingNext && <Spinner variant="light" className="m-auto" animation="border" size="sm" />}
                  <span className="ms-2">
                    <Trans>Proceed to Checkout</Trans>
                  </span>
                </CustomButton>
                {usCheckoutError &&
                  <p className="text-danger">{usCheckoutError}</p>
                }
              </div>
            </div>
          </Col>
          {mobile ? null : (
            <Col sm={12} md={5}>
              <Box>
                <Title style={{ color: "black" }}>
                  <Trans>Plan</Trans>
                </Title>
                <div className="pb-4">
                  <hr />
                </div>
                <Row>
                  <Col sm={12}>
                    <SimCardDetail planId={shopDetails.planId} disableEdit={disableEditBar} disableChangePlanBtn={disableChangeBtn} />
                  </Col>
                </Row>
                <div className="pt-4">
                  <hr />
                </div>
                <Title className="mt-4" style={{ color: "black" }}>
                  <Trans>Details</Trans>
                </Title>
                <div className="d-flex flex-row justify-content-start align-items-center"></div>
                {(shopDetails.country === constants.CANADA && !shopDetails.dataOnly) ? (
                  <Detail>
                    {(shopDetails.data + "" !== "Unlimited") &&
                      <DetailItem>
                        {formatPhoneData(shopDetails.data, shopDetails.planTypeD.toString()) + " " + t(`high speed ${isTelusPlan ? '4G LTE/5G' : '5G'} data`)}
                      </DetailItem>
                    }
                    <DetailItem>
                      <Trans>UNLIMITED Canada-wide calling</Trans>
                    </DetailItem>
                    <DetailItem>
                      <Trans>
                        UNLIMITED International text and picture messaging
                      </Trans>
                    </DetailItem>
                    {isTelusPlan && <DetailItem>
                      <Trans>
                        UNLIMITED international calling
                      </Trans>
                    </DetailItem>}
                    {!isTelusPlan &&
                      <DetailItem>
                        <Trans>
                          Supported on the {shopDetails.planCarrier} Network
                        </Trans>
                      </DetailItem>
                    }
                    <DetailItem>
                      <Trans>Call Display</Trans>
                    </DetailItem>
                    <DetailItem>
                      <Trans>Voicemail - Up to 35 minutes of messages</Trans>
                    </DetailItem>
                    {!shopDetails.noTopUpFee && (shopDetails.data + "" !== "Unlimited") && <DetailItem>
                      <Trans>Data {shopDetails.isPostpaid ? "Overage" : "TopUp"} Rate: $15.00 / 1GB</Trans>
                    </DetailItem>}
                  </Detail>
                ) : shopDetails.country === constants.USA ? null : (
                  <Detail>
                    <DetailItem>
                      Supported on the Rogers Network
                    </DetailItem>
                  </Detail>
                )}

                {shopDetails.country !== constants.CANADA &&
                  planIncludesDescription[shopDetails.planId] && (
                    <Detail>
                      {planIncludesDescription[shopDetails.planId].map(
                        (entry, i) => {
                          if (entry.includes("75+ Countries")) {
                            let index = entry.indexOf("75+ Countries");
                            let firstPart = entry.substring(0, index);
                            return (
                              <DetailItem key={i}>
                                <Trans>{firstPart}</Trans>
                                <span
                                  style={{ color: "#22C3F1" }}
                                  data-elevio-article="82"
                                  className="_elevio_underline ctxUnderline"
                                  data-elevio-unique-id="8"
                                >
                                  75+ <Trans>Countries</Trans>
                                </span>
                              </DetailItem>
                            );
                          }

                          return (
                            <DetailItem>
                              <Trans>{entry}</Trans>
                            </DetailItem>
                          );
                        }
                      )}
                    </Detail>
                  )}

                <Title style={{ color: "black" }}>
                  <Trans>Please Note:</Trans>
                </Title>
                <Detail>
                  <DetailItem>
                    <Trans>Plan does not include International Roaming</Trans>
                  </DetailItem>
                  {shopDetails.country === "Canada" && (
                    <DetailItem>
                      <Trans>
                        Provincial 9-1-1 fees are extra (If applicable)
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.dataOnly && (
                    <DetailItem>
                      <Trans>
                        Calling & Texting services are not available
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.simcard_no === "" &&
                    <>
                      {shopDetails.fromPartner &&
                        <DetailItem>
                          {t("A SIM card fee of $10 will be charged (If applicable)")}
                        </DetailItem>
                      }
                      {!shopDetails.fromPartner &&
                        <DetailItem>
                          <Trans>A SIM card fee of $10 will be charged</Trans>
                        </DetailItem>
                      }
                    </>
                  }
                  {shopDetails.planId === 7 && (
                    <DetailItem>
                      <Trans>International calling available as an add-on for $10</Trans>
                    </DetailItem>
                  )}
                  {shopDetails.country === "USA" && shopDetails.planTypeD.toString() === '7' && (
                    <>
                      <DetailItem>
                        <Trans>International calling option is unavailable for this plan.</Trans>
                      </DetailItem>
                      <DetailItem>
                        <Trans>The plan is strictly limited to 7 days.</Trans>
                      </DetailItem>
                      <DetailItem>
                        <Trans>Term extension is unavailable.</Trans>
                      </DetailItem>
                      <DetailItem>
                        <Trans>SIM card is not allowed to be reused.</Trans>
                      </DetailItem>
                    </>
                  )}
                  {shopDetails.country === "USA" && shopDetails.planTypeD.toString() !== '7' && (
                    <DetailItem>
                      <Trans>Data Top-up rates: $10/GB</Trans>
                    </DetailItem>
                  )}
                </Detail>
              </Box>
            </Col>
          )}
        </Row>
      </Container>
    </PageLayout >
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Ul = styled.ul<{ padding?: string; left?: string }>`
  margin-left: ${({ left }) => left || "25px"};
  padding-left: ${({ padding }) => padding || "1rem"};
`;

const Li = styled.li<{ size?: string }>`
  font-size: 20px;
  font-weight: 600;
  list-style-type: none;
  @media (max-width: 440px) {
    font-size: 17px;
  }
`;

const LiCont = styled.div`
  display: flex;
  align-items: center;
`;

const Box = styled.div<{ margin?: string }>`
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 30px;
  margin: 40px 0px;
  @media (max-width: 768px) {
    margin: ${({ margin }) => margin || "40px 10px"};
    background-color: white;
    padding: 0px;
  }
`;
interface SummaryBoxProps {
  mobile: boolean;
}
const SummaryBox = styled.div<SummaryBoxProps>`
  background-color: ${({ mobile }) => (mobile ? "white" : "#f8f8f8")};
  border-radius: 20px;
  padding: 40px 80px;
  margin: 40px 0px;
  @media (max-width: 990px) {
    margin: 40px 10px;
    padding: 40px 30px;
  }
`;

const Title = styled.p`
  color: #0594ca;
  font-weight: 500;
  font-size: 20px;
`;

const Label = styled.div<{ topGap?: string; bottomGap?: string }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-top: ${(props) => props.topGap};
  margin-bottom: ${(props) => props.bottomGap};
  width: max-content;
  span {
    color: red;
  }

  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

const TextSmall = styled.p`
  font-size: 16px;
  font-weight: 300;
`;

const TextSmallBold = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

const RequiredLabel = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 500;
  :after {
    color: #e32;
    content: " *";
    display: inline;
  }

  @media (max-width: 418px) {
    font-size: 14px;
  }
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: black;
  font-weight: 200;
  color: red;
  font-weight: 500;
`;

const EsimQuestion = styled.p`
  text-align: right;
  color: #0594ca;
`;

const Detail = styled.ul`
  margin: 30px 0px;
`;

const DetailItem = styled.li`
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0px;
`;

const CustomButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  margin: 0px;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 15px 0px;
  :hover {
    color: #fff;
  }
`;

const CarrierErrorMsg = styled.div`
  color: red;
  margin-top: 12px;
  text-align: center;
`

const Price = styled(Label) <{ details?: string }>`
  color: #01bae5;
  font-weight: 500;
  font-size: 17px;
  margin-left: ${({ details }) => details || 0};
  @media (max-width: 990px) {
    float: right;
    margin-left: 0;
  }
`;

const PromoPrice = styled(Label) <{ details?: string }>`
  width: 100%;
  color: #01bae5;
  font-weight: 500;
  font-size: 17px;
  margin-left: 0;
  text-align: right;
  
  @media (min-width: 990px) {
    margin-left: -14px;
    text-align: initial;
  }

  @media (min-width: 1200px) {
    margin-left: 37px;
  }

  @media (min-width: 1400px) {
    margin-left: ${({ details }) => details || 0};
    text-align: initial;
  }
`;

const ErrorMessage = styled.div`
  color: red;
`;

const BackArrow = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const Head = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          } 

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `
      }}/>
    </>
  )
}